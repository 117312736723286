<template>
  <v-row justify="justify-space-between" no-gutters class="d-none d-sm-flex">
    <v-col>
      <v-system-bar color="#c98288">
        <v-icon color="white" class="pl-3">mdi-phone-dial-outline</v-icon>
        <span class=""><strong>+880247112923</strong> </span>
        <v-spacer></v-spacer>
        <v-icon class="pl-4" color="white">mdi-map-marker</v-icon>
        <span>
          <strong
            >Shadharan Bima Tower (7th Floor) 37/A, Dilkusha C/A
            Dhaka-1000</strong
          ></span
        >
        <v-spacer></v-spacer>
        <v-icon class="pl-4" color="white">mdi-fax</v-icon>
        <span> <strong>PABX: + 88-02-47118073</strong></span>

        <v-spacer></v-spacer>
        <v-icon color="white">mdi-email</v-icon>
        <span class="pr-4"> <strong>info@ril.com.bd</strong></span>
        <!-- <v-icon>mdi-checkbox-blank-outline</v-icon>
      <v-icon>mdi-close</v-icon> -->
      </v-system-bar>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
